import { Injectable } from '@angular/core';
import { SecureSimpleStorage } from './storage/secure-simple-storage';
import { Contract } from './storage/contract';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GetCountryDataService {
    constructor(private secureStorage: SecureSimpleStorage) {}

    async saveData() {
        try {
            let userString = await this.secureStorage
                .get(Contract.user)
                .toPromise();
            let user = JSON.parse(userString);
            let userOV = user.ObjectValue[0];
            if (userOV?.Accounts[0]?.TacName == 'Interno') {
                if (
                    userOV?.Accounts[0]?.RolName == 'SAC web' ||
                    userOV?.Accounts[0]?.RolName == 'Operaciones web'
                ) {
                    environment.multiCountryData.push({
                        nationality: userOV.Profile[0].Nationality,
                        nationalityAccount: userOV.Profile[1].IdCountry,
                        north:
                            userOV.Profile[1].IdCountry == 'GT'
                                ? 18.915697
                                : 16.5100661,
                        south:
                            userOV.Profile[1].IdCountry == 'GT'
                                ? 12.0121
                                : 12.9808485,
                        west:
                            userOV.Profile[1].IdCountry == 'GT'
                                ? -92.988
                                : -89.363468,
                        east:
                            userOV.Profile[1].IdCountry == 'GT'
                                ? -88.19820009999999
                                : -83.1342521,
                        lat:
                            userOV.Profile[1].IdCountry == 'GT'
                                ? 14.64072
                                : 14.6,
                        lng:
                            userOV.Profile[1].IdCountry == 'GT'
                                ? -90.51327
                                : -86.5,
                    });
                }
                return;
            }
            try {
                let country = await this.secureStorage
                    .get(Contract.multiCountryData)
                    .toPromise();
                let data = JSON.parse(country);
                environment.multiCountryData.push(data);
            } catch (error) {
                environment.multiCountryData.push({
                    currency: userOV.Currency,
                    currencySymbol: userOV.Currency == 'GTQ' ? 'Q' : 'L',
                    nationality: userOV.Profile[0].Nationality,
                    nameIdentification:
                        userOV.Currency == 'GTQ' ? 'DPI' : 'DNI',
                    nameBilling: userOV.Currency == 'GTQ' ? 'NIT' : 'RTN',
                    nationalityAccount: userOV.Currency == 'GTQ' ? 'GT' : 'HN',
                    nameAccountMonet:
                        userOV.Currency == 'GTQ' ? 'Monetaria' : 'Cheques',
                    maxLengthIdentification: userOV.Currency == 'GTQ' ? 13 : 13,
                    maxLengthBilling: userOV.Currency == 'GTQ' ? 13 : 14,
                    north: userOV.Currency == 'GTQ' ? 18.915697 : 16.5100661,
                    south: userOV.Currency == 'GTQ' ? 12.0121 : 12.9808485,
                    west: userOV.Currency == 'GTQ' ? -92.988 : -89.363468,
                    east:
                        userOV.Currency == 'GTQ'
                            ? -88.19820009999999
                            : -83.1342521,
                    lat: userOV.Currency == 'GTQ' ? 14.64072 : 14.6,
                    lng: userOV.Currency == 'GTQ' ? -90.51327 : -86.5,
                    InsuranceExempt: userOV.Accounts[0].InsuranceExempt,
                    CashOnDeliveryCharge:
                        userOV.Accounts[0].CashOnDeliveryCharge,
                    CODPercentage: userOV.Accounts[0].CODPercentage,
                    InsuranceRate: userOV.Accounts[0].InsuranceRate,
                });
                await this.secureStorage
                    .save(
                        Contract.multiCountryData,
                        JSON.stringify(environment.multiCountryData[0])
                    )
                    .toPromise();
            }
        } catch (err) {}
    }

    getCountries() {
        let countries: any = [
            {
                text: 'Guatemala',
                countryName: 'Guatemala',
                icon: 'icon-gt',
                value: 'GT',
                prefixPhone: '+502',
                currency: 'GTQ',
            },
            {
                text: 'Honduras',
                countryName: 'Honduras',
                icon: 'icon-hn',
                value: 'HN',
                prefixPhone: '+504',
                currency: 'HNL',
            },
        ];
        return countries;
    }

    getMultiCountryDataWithoutLogin() {
        let countries: any[] = [];
        this.getCountries().forEach((item: any) => {
            countries.push({
                text: item.text,
                countryName: item.countryName,
                icon: item.icon,
                prefixPhone: item.prefixPhone,
                currency: item.currency,
                currencySymbol: item.currency == 'GTQ' ? 'Q' : 'L',
                nationality: item.value,
                nameIdentification: item.currency == 'GTQ' ? 'DPI' : 'DNI',
                nameBilling: item.currency == 'GTQ' ? 'NIT' : 'RTN',
                nationalityAccount: item.currency == 'GTQ' ? 'GT' : 'HN',
                nameAccountMonet:
                    item.currency == 'GTQ' ? 'Monetaria' : 'Cheques',
                maxLengthIdentification: item.currency == 'GTQ' ? 13 : 14,
                maxLengthBilling: item.currency == 'GTQ' ? 13 : 14,
                north: item.currency == 'GTQ' ? 18.915697 : 16.5100661,
                south: item.currency == 'GTQ' ? 12.0121 : 12.9808485,
                west: item.currency == 'GTQ' ? -92.988 : -89.363468,
                east: item.currency == 'GTQ' ? -88.19820009999999 : -83.1342521,
                lat: item.currency == 'GTQ' ? 14.64072 : 14.6,
                lng: item.currency == 'GTQ' ? -90.51327 : -86.5,
                InsuranceExempt: item.currency == 'GTQ' ? '800.00' : '1000.00',
                CashOnDeliveryCharge: item.currency == 'GTQ' ? '4.00' : '10.00',
                CODPercentage: item.currency == 'GTQ' ? '3.8' : '12.14',
                InsuranceRate: item.currency == 'GTQ' ? '1.50' : '1.00',
                idCurrencySymbol: item.currency == 'GTQ' ? 1 : 4,
            });
        });
        return countries;
    }

    async saveDataWithOutLogin() {
        try {
            let country = await firstValueFrom(
                this.secureStorage.get(Contract.multiCountryData)
            );
            let data = JSON.parse(country);
            data.userWOLGT = {
                IdAccount: 78690,
                IdCustomer: 68508,
            };
            data.userWOLHN = {
                IdAccount: 78696,
                IdCustomer: 68515,
            };
            environment.multiCountryData.push(data);
        } catch (error) {
            environment.multiCountryData.push({
                userWOLGT: {
                    IdAccount: 78690,
                    IdCustomer: 68508,
                },
                userWOLHN: {
                    IdAccount: 78696,
                    IdCustomer: 68515,
                },
            });
        } finally {
            await firstValueFrom(
                this.secureStorage.save(
                    Contract.multiCountryData,
                    JSON.stringify(environment.multiCountryData[0])
                )
            );
        }
    }
}
