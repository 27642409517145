import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { GetCountryDataService } from '../get-country-data.service';
import { SecureSimpleStorage } from '../storage/secure-simple-storage';
import { Contract } from '../storage/contract';

@Injectable({
    providedIn: 'root',
})
export class GuideWithoutLoginService {
    countrySelect: any = null;

    // Emite el último valor a todos los nuevos suscriptores
    private valueCountry = new BehaviorSubject<any>(null);
    private valueStep = new BehaviorSubject<any>(false);

    constructor(
        private getCountryData: GetCountryDataService,
        private secureStorageService: SecureSimpleStorage
    ) {
        this.countrySelect =
            this.getCountryData.getMultiCountryDataWithoutLogin()[0];
        this.assignCountry();
    }

    // Observable al que los componentes se pueden suscribir
    currentCountry$ = this.valueCountry.asObservable();
    currentStep$ = this.valueStep.asObservable();

    changeCountry(value: any) {
        this.valueCountry.next(value);
    }

    // para mostrar o no el select del header
    changeStep(value: boolean) {
        this.valueStep.next(value);
    }

    async assignCountry() {
        let dataUser = await firstValueFrom(
            this.secureStorageService.get(Contract.userWOL)
        );
        let userWOL = JSON.parse(dataUser);
        if (userWOL && userWOL.multiCountryData) {
            this.changeCountry(userWOL.multiCountryData);
        }
    }
}
